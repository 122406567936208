import ReactGA from 'react-ga4'

function useOrderTracking (): {
  actionEvent: (action: string) => void
} {
  return {
    actionEvent: (action: string): void => {
      ReactGA.event('action_item', {
        item_list_id: 'purchase_order',
        action
      })
    }
  }
}

export default useOrderTracking
