import { type ReactElement } from 'react'
import useAccounts from '../../common/hooks/useAccounts'
import useApi from '../../common/hooks/useApi'
import { OrderSortType } from '../types/order-sort-type'
import { SortDirection } from '../../common/types/sort-direction'
import { OrderSearchType } from '../types/order-search-type'
import ExportCsvButton from '../../common/components/ExportCsvButton'
import { useTranslation } from 'react-i18next'
import { type Order } from '@amici/myamici-api-client'
import useOrderTracking from '../hooks/useOrderTracking'

export interface ExportOrdersButtonProps {
  query?: string
  sort: OrderSortType
  orderSearchType: OrderSearchType
  dateFrom?: string | undefined
  dateTo?: string | undefined
  facetFilter?: string[]
}

const total = (order: Order): number =>
  (order.carriage_charge ?? 0) + (order.order_charge ?? 0) + (order.value ?? 0)

function ExportOrdersButton ({
  query,
  sort,
  orderSearchType,
  dateFrom,
  dateTo,
  facetFilter
}: Readonly<ExportOrdersButtonProps>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { ordersApi, fetcher } = useApi()
  const { actionEvent } = useOrderTracking()

  const direction =
    sort === OrderSortType.OrderedBy || sort === OrderSortType.Supplier
      ? SortDirection.ASC
      : SortDirection.DESC

  const fetchData = async (): Promise<string[][]> => {
    const accountId = activeAccount?.accountId as string
    const data = await fetcher(ordersApi.getPage, {
      accountId,
      query,
      page: 1,
      size: 0,
      sort,
      direction,
      facetFilter,
      returnResults: true,
      dateFrom,
      dateTo,
      includeClientPurchases: orderSearchType === OrderSearchType.AllOrders,
      returnFacets: false
    })

    const rows = data.content
      ? data.content.map(order => [
          order.reference,
          order.supplier?.name ?? '',
          order.order_date
            ? t('date', {
                date: new Date(order.order_date)
              })
            : '',
          order.ordered_by?.name ?? '',
          t('common.price', {
            price: total(order),
            currency: order.currency ?? 'GBP'
          }),
          t('order.export.status', {
            context: order.status
          }),
          order.estimated_delivery_date
            ? t('date', {
                date: new Date(order.estimated_delivery_date)
              })
            : '',
          t('order.export.method', {
            context: order.method
          }),
          t('order.export.type', {
            context: order.order_type
          }),
          order.confidential + ''
        ])
      : []
    const headers = [
      t('order.export.header.reference'),
      t('order.export.header.supplier'),
      t('order.export.header.date'),
      t('order.export.header.ordered_by'),
      t('order.export.header.total'),
      t('order.export.header.status'),
      t('order.export.header.estimated_delivery_date'),
      t('order.export.header.method'),
      t('order.export.header.type'),
      t('order.export.header.confidential')
    ]
    return [headers, ...rows]
  }

  return (
    <ExportCsvButton
      fetchData={fetchData}
      onExportStart={() => {
        actionEvent('export_csv')
      }}
    />
  )
}

export default ExportOrdersButton
