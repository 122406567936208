import ReactGA from 'react-ga4'

export interface UseOrderRequestTrackingEventResult {
  trackEvent: (action: string) => void
  trackSupplierEvent: (itemId: string, action: string) => void
}

function useOrderRequestTrackingEvent (
  orderRequestId?: string
): UseOrderRequestTrackingEventResult {
  const trackEvent = (action: string): void => {
    ReactGA.event('action_item', {
      item_list_id: 'order_request',
      item_id: orderRequestId,
      action
    })
  }

  const trackSupplierEvent = (itemId: string, action: string): void => {
    ReactGA.event('action_item', {
      item_list_id: 'order_request',
      item_id: itemId,
      action
    })
  }

  return {
    trackEvent,
    trackSupplierEvent
  }
}

export default useOrderRequestTrackingEvent
