import type { SpendCategory } from '@amici/myamici-api-client'
import {
  type PropsWithChildren,
  type ReactElement,
  type RefObject,
  useEffect,
  useRef,
  useState
} from 'react'
import { useTranslation } from 'react-i18next'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import MaConfirm from '../../common/components/MaConfirm'
import SpendCategoryFieldSelect from '../../spend-categories/components/SpendCategoryFieldSelect'

// TODO: This is not ideal as the same data is fetched inside the <SpendCategoryFieldSelect /> component.
// A larger refactoring effort will be required.
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'

const FULL_VIEW_MIN_WIDTH_PX = 992

interface GroupEditSpendCategoriesModalProps extends PropsWithChildren {
  assignedFieldIds?: string[]
  allowEmpty?: boolean
  show: boolean
  title?: string
  onSave: (spendCategories: SpendCategory[]) => Promise<void>
  onCancel: () => void
  disabled: boolean
}

const spendCategoryComparator = (
  spendCategory1: SpendCategory,
  spendCategory2: SpendCategory
): number => spendCategory1.field_id.localeCompare(spendCategory2.field_id)

function GroupEditSpendCategoriesModal ({
  assignedFieldIds,
  allowEmpty = true,
  show,
  title,
  children,
  onSave,
  onCancel,
  disabled
}: Readonly<GroupEditSpendCategoriesModalProps>): ReactElement {
  const { data: spendCategoryFields } = useSpendCategories(true)
  const { t } = useTranslation()
  const [spendCategories, setSpendCategories] = useState<SpendCategory[]>([])
  const ref = useRef<any>(null)
  const compactView = !useMinWidthObserver(
    ref.current?.dialog as RefObject<Element>,
    FULL_VIEW_MIN_WIDTH_PX
  )

  useEffect(() => {
    setSpendCategories([])
  }, [show])

  const onSpendCategoryFieldSelect = (
    newSpendCategory: SpendCategory
  ): void => {
    const updatedSpendCategories = [
      ...spendCategories.filter(
        value => value.field_id !== newSpendCategory.field_id
      )
    ]
    if (newSpendCategory.id !== -1) {
      updatedSpendCategories.push(newSpendCategory)
    }
    updatedSpendCategories.sort(spendCategoryComparator)
    setSpendCategories(updatedSpendCategories)
  }

  const isDisabled = (): boolean => {
    if (disabled || (!allowEmpty && assignedFieldIds?.length === 0)) {
      return true
    } else if (allowEmpty) {
      // We don't care, if allowEmpty is explictly true - enable save
      return false
    }

    const newSelections = spendCategories.map(
      spendCategory => spendCategory.field_id
    )

    const selectedFieldIds = [
      ...new Set([...(assignedFieldIds ?? []), ...newSelections])
    ]

    return !(
      newSelections.length > 0 &&
      selectedFieldIds.length >= (spendCategoryFields?.length ?? 0)
    )
  }

  return (
    <MaConfirm
      ref={ref}
      size="lg"
      title={title}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      show={show}
      disabled={isDisabled()}
      onConfirm={() => {
        void onSave(spendCategories)
      }}
      onClose={onCancel}
    >
      {children}
      <SpendCategoryFieldSelect
        assignedFieldIds={assignedFieldIds ?? []}
        compactView={compactView}
        selectedSpendCategories={spendCategories}
        placeholder={t('spend_categories.label.no_change')}
        allowEmpty={allowEmpty ?? true}
        emptyNodeName={t('spend_categories.label.no_change')}
        excludeInactive={true}
        onValueChange={onSpendCategoryFieldSelect}
        disabled={disabled}
      />
    </MaConfirm>
  )
}

export default GroupEditSpendCategoriesModal
