function useAutoDownload (): (href: string, download: string) => void {
  return (href: string, download: string): void => {
    const a = document.createElement('a')
    a.href = href
    a.download = download
    a.click()
  }
}

export default useAutoDownload
