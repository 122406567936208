import {
  type Orders,
  OrderStatusEnum,
  type OrderLineItemResource,
  type OrderResource
} from '@amici/myamici-api-client'
import { Fragment, useState, type ReactElement, type ReactNode } from 'react'
import {
  BsCaretDownFill,
  BsCaretRightFill,
  BsExclamationCircle
} from 'react-icons/bs'
import { Collapse, Table } from 'react-bootstrap'
import { type TFunctionNonStrict } from 'i18next'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../common/components/LoadingSpinner'
import useOrderItems from '../hooks/useOrderItems'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import MaPagination from '../../common/components/MaPagination'
import MaTooltip from '../../common/components/MaTooltip'
import styles from '../assets/scss/ReceiveOrdersTable.module.scss'

const formatLineItemIssue = (
  lineItem: OrderLineItemResource,
  issue: string,
  t: TFunctionNonStrict
): string =>
  t(`order.items.issue.${issue}`, {
    on_hold_date: new Date(lineItem.on_hold_date ?? ''),
    formatParams: {
      on_hold_date: {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }
    }
  })

function ReceiveOrdersTableHead (): ReactElement {
  const { t } = useTranslation()

  return (
    <thead>
      <tr>
        <th />
        <th scope="col">{t('receive_orders.table.column.po_number')}</th>
        <th scope="col">{t('receive_orders.table.column.supplier')}</th>
        <th scope="col">{t('receive_orders.table.column.order_date')}</th>
        <th scope="col">{t('receive_orders.table.column.ordered_by')}</th>
        <th scope="col">{t('receive_orders.table.column.or_number')}</th>
        <th scope="col">{t('receive_orders.table.column.status')}</th>
        <th scope="col">
          {t('receive_orders.table.column.est_delivery_date')}
        </th>
        <th />
      </tr>
    </thead>
  )
}

function ReceiveOrdersLineItemTableRow ({
  lineItem,
  handleReceiveOrEdit
}: Readonly<{
  lineItem: OrderLineItemResource
  handleReceiveOrEdit: (lineItem: OrderLineItemResource) => void
}>): ReactElement {
  const { t } = useTranslation()

  const outstandingQuantity =
    lineItem.quantity - (lineItem.quantity_received ?? 0)
  const fullyReceived = lineItem.quantity === lineItem.quantity_received

  const lineItemIssues: string =
    lineItem.issues
      ?.map(issue => formatLineItemIssue(lineItem, issue, t))
      ?.join(', ') ?? ''

  return (
    <tr>
      <td className={styles.spn}>
        {lineItemIssues && (
          <MaTooltip text={lineItemIssues}>
            <BsExclamationCircle size={16} className="text-warning" />
          </MaTooltip>
        )}

        {lineItem.product.part_number}
      </td>
      <td className={styles.product}>
        <p>
          <Link to={`/purchasing/products/${lineItem.product.id}`}>
            {lineItem.product.description}
          </Link>
        </p>
      </td>
      <td className={styles['pack-size']}>
        {t('common.product.pack_size_value', {
          count: lineItem.product.no_of_units,
          packSizeValue: lineItem.product.pack_size,
          packSizeUnit: lineItem.product.pack_size_unit
        })}
      </td>
      <td>{lineItem.quantity}</td>
      <td>{lineItem.quantity_received ?? 0}</td>
      <td
        className={classNames({
          [styles.outstanding]: outstandingQuantity > 0
        })}
      >
        {outstandingQuantity}
      </td>
      <td className={styles['due-date']}>
        {lineItem.estimated_delivery_date
          ? t('date', { date: new Date(lineItem.estimated_delivery_date) })
          : '-'}
      </td>
      <td>
        <MaActionMenu aria-label={t('receive_orders.item.actions.title')}>
          <MaActionMenuItem
            onClick={() => {
              handleReceiveOrEdit(lineItem)
            }}
          >
            {fullyReceived
              ? t('receive_orders.item.action.edit_received')
              : t('receive_orders.item.action.receive_and_edit')}
          </MaActionMenuItem>
        </MaActionMenu>
      </td>
    </tr>
  )
}

function ReceiveOrdersTableRow ({
  order,
  handleReceiveItemsModalOpen
}: Readonly<{
  order: OrderResource
  handleReceiveItemsModalOpen: (
    order: OrderResource,
    items: OrderLineItemResource[],
    showLineItemModal: boolean
  ) => void
}>): ReactElement {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  const { data, isLoading, estimatedDeliveryDate } = useOrderItems(order.id)

  const orderIssues: string = [
    ...(data?.content?.reduce((issues, lineItem) => {
      lineItem.issues?.forEach(issue =>
        issues.add(formatLineItemIssue(lineItem, issue, t))
      )
      return issues
    }, new Set<string>()) ?? new Set())
  ].join(', ')

  return (
    <Fragment key={order.id}>
      <tr>
        <th
          scope="row"
          onClick={() => {
            setOpen(!open)
          }}
        >
          {open ? (
            <BsCaretDownFill aria-label={t('common.button.labels.collapse')} />
          ) : (
            <BsCaretRightFill aria-label={t('common.button.labels.expand')} />
          )}
        </th>
        <td>
          {orderIssues && !open && (
            <MaTooltip text={orderIssues}>
              <BsExclamationCircle size={16} className="text-warning" />
            </MaTooltip>
          )}

          <Link to={`/purchasing/orders/${order.id}`}>{order.reference}</Link>
        </td>
        <td>{order.supplier?.name}</td>
        <td>
          {order.order_date
            ? t('date', { date: new Date(order.order_date) })
            : '-'}
        </td>
        <td>{order.ordered_by?.name}</td>
        <td>{order.order_request_reference?.reference}</td>
        <td>
          {t(`order.status.${order.status}`, {
            ...(estimatedDeliveryDate && {
              date: estimatedDeliveryDate
            })
          })}
        </td>
        <td>
          {estimatedDeliveryDate
            ? t('date', { date: estimatedDeliveryDate })
            : '-'}
        </td>
        <td>
          <MaActionMenu aria-label={t('receive_orders.order.actions.title')}>
            <MaActionMenuItem
              disabled={order.status === OrderStatusEnum.COMPLETE}
              onClick={() => {
                handleReceiveItemsModalOpen(order, data?.content ?? [], false)
              }}
            >
              {t('receive_orders.order.actions.receive_outstanding')}
            </MaActionMenuItem>
          </MaActionMenu>
        </td>
      </tr>
      <tr className={styles.expandable}>
        <td colSpan={11}>
          <Collapse in={open}>
            <div>
              <div className={styles['inner-wrapper']} aria-expanded={open}>
                {isLoading ? (
                  <LoadingSpinner />
                ) : (
                  <Table
                    aria-label={t('receive_orders.line_items.title')}
                    className={styles.nested}
                  >
                    <thead>
                      <tr>
                        <th>
                          {t('receive_orders.table.line_items.column.spn')}
                        </th>
                        <th>
                          {t('receive_orders.table.line_items.column.product')}
                        </th>
                        <th>
                          {t(
                            'receive_orders.table.line_items.column.pack_size'
                          )}
                        </th>
                        <th>
                          {t('receive_orders.table.line_items.column.ordered')}
                        </th>
                        <th>
                          {t('receive_orders.table.line_items.column.received')}
                        </th>
                        <th className={styles['outstanding-col']}>
                          {t(
                            'receive_orders.table.line_items.column.outstanding'
                          )}
                        </th>
                        <th>
                          {t('receive_orders.table.line_items.column.due')}
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      {data?.content?.map(lineItem => (
                        <ReceiveOrdersLineItemTableRow
                          key={lineItem.id}
                          lineItem={lineItem}
                          handleReceiveOrEdit={lineItem => {
                            handleReceiveItemsModalOpen(order, [lineItem], true)
                          }}
                        />
                      ))}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          </Collapse>
        </td>
      </tr>
    </Fragment>
  )
}

interface ReceiveOrdersTableProps {
  orders?: Orders
  onPageChange: (page: number) => void
  handleReceiveItemsModalOpen: (
    order: OrderResource,
    items: OrderLineItemResource[],
    showLineItemModal: boolean
  ) => void
}

const DEFAULT_PAGE_SIZE = 50

function ReceiveOrdersTable ({
  orders,
  onPageChange,
  handleReceiveItemsModalOpen
}: Readonly<ReceiveOrdersTableProps>): ReactNode {
  const { t } = useTranslation()
  if ((orders?.content?.length ?? 0) < 1) {
    return null
  }

  const page = orders?.page ?? 1
  const pageSize = orders?.size ?? DEFAULT_PAGE_SIZE
  const pageStartRow = (page - 1) * pageSize + 1
  const pageEndRow = pageStartRow + (orders?.content?.length ?? 0) - 1

  return (
    <div className={styles['receive-orders-table']}>
      <Table responsive aria-label={t('receive_orders.title')}>
        <ReceiveOrdersTableHead />

        <tbody>
          {orders?.content?.map(order => (
            <ReceiveOrdersTableRow
              key={order.id}
              order={order}
              handleReceiveItemsModalOpen={handleReceiveItemsModalOpen}
            />
          ))}
        </tbody>
      </Table>

      <MaPagination
        className="pagination"
        totalPages={orders?.totalPages ?? 0}
        currentPage={page}
        sizeOptions={[DEFAULT_PAGE_SIZE]}
        pageSize={pageSize}
        pageStartRow={pageStartRow}
        pageEndRow={pageEndRow}
        totalRowsCount={orders?.totalElements ?? 0}
        standalone={false}
        onPageChange={onPageChange}
        onPageSizeChange={() => {}}
      />
    </div>
  )
}

export default ReceiveOrdersTable
