import { type ReactElement, type HTMLAttributes, useRef } from 'react'
import {
  OrderStatusEnum,
  type OrderRequestReference,
  type OrderLineItemResource
} from '@amici/myamici-api-client'
import { Trans, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { type CheckedState } from '../../common/components/MaCheckbox'
import MaPanel from '../../common/components/MaPanel'
import OrderLineItems from './OrderLineItems'
import useAccounts from '../../common/hooks/useAccounts'
import usePurchasingPermissions from '../hooks/usePurchasingPermissions'
import MaActionMenu, {
  MaActionMenuItem
} from '../../common/components/MaActionMenu'
import { UserPermission } from '../../common/types/user-permission'
import useSpendCategories from '../../spend-categories/hooks/useSpendCategories'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'
import ToggleButtonGroup from '../../common/components/ToggleButtonGroup'
import { BsGrid, BsList } from 'react-icons/bs'
import PurchaseOrderOrderItemsTable from './PurchaseOrderOrderItemsTable'
import useMinWidthObserver from '../../common/hooks/useMinWidthObserver'
import { useLocalStorage } from 'usehooks-ts'

const FULL_VIEW_MIN_WIDTH_PX = 768

enum ViewType {
  Card,
  Table
}

export interface PurchaseOrderOrderItemsProps
  extends HTMLAttributes<HTMLElement> {
  lineItems?: OrderLineItemResource[]
  orderStatus?: OrderStatusEnum
  orderRequestReference?: OrderRequestReference | null
  selectedLineItemIds?: string[]
  onEditLineItem: (lineItem: OrderLineItemResource) => void
  onGroupEdit?: () => void
  onReceiveOutstanding?: () => void
  onItemSelectedChange?: (
    lineItem: OrderLineItemResource,
    checked: CheckedState
  ) => void
}

function PurchaseOrderOrderItems ({
  lineItems,
  orderStatus,
  orderRequestReference,
  selectedLineItemIds,
  onEditLineItem,
  onGroupEdit,
  onReceiveOutstanding,
  onItemSelectedChange,
  ...props
}: Readonly<PurchaseOrderOrderItemsProps>): ReactElement {
  const { t } = useTranslation()
  const { activeAccount } = useAccounts()
  const { isFinanceUser } = usePurchasingPermissions(activeAccount)
  const { data: spendCategories } = useSpendCategories(true)
  const [selectedView, setSelectedView] = useLocalStorage(
    'PurchaseOrderOrderItems',
    ViewType.Card
  )

  const ref = useRef(null)
  const compactView = !useMinWidthObserver(ref, FULL_VIEW_MIN_WIDTH_PX)

  const canEditSpendCategories =
    isFinanceUser && (spendCategories?.length ?? 0) > 0
  const isGoodsInUser = activeAccount?.permissions.includes(
    UserPermission.IsGoodsInUser
  )

  const toggleSelectedView = (): void => {
    setSelectedView(
      selectedView === ViewType.Card ? ViewType.Table : ViewType.Card
    )
  }

  return (
    <MaPanel className={props.className} ref={ref} {...props}>
      <MaPanel.Header className={styles.header}>
        <h5 className={styles.title}>
          <span>
            {orderRequestReference ? (
              <Trans
                i18nKey={t(
                  'purchase_order.order_items.title_with_order_request_link'
                )}
                values={{
                  count: lineItems?.length ?? 0
                }}
                components={{
                  OrderRequestLink: (
                    <Link
                      to={`/purchasing/order-requests/${orderRequestReference?.id}`}
                    >
                      {orderRequestReference?.reference}
                    </Link>
                  )
                }}
              />
            ) : (
              <Trans
                i18nKey={t('purchase_order.order_items.title')}
                values={{
                  count: lineItems?.length ?? 0
                }}
              />
            )}
          </span>
        </h5>

        {!compactView && (
          <ToggleButtonGroup
            selectedBtnIndex={selectedView}
            onClick={toggleSelectedView}
          >
            <BsGrid size={24} title={t('common.view.card')} />
            <BsList size={24} title={t('common.view.table')} />
          </ToggleButtonGroup>
        )}

        <MaActionMenu aria-label={t('purchase_order.order_items.actions')}>
          {canEditSpendCategories && (
            <MaActionMenuItem
              disabled={(selectedLineItemIds?.length ?? 0) < 1}
              onClick={onGroupEdit}
            >
              {t(
                'purchase_order.order_items.action.group_edit_spend_categories'
              )}
            </MaActionMenuItem>
          )}
          {isGoodsInUser && (
            <MaActionMenuItem
              disabled={
                !orderStatus ||
                !(
                  [
                    OrderStatusEnum.AWAITING_DELIVERY_INFORMATION,
                    OrderStatusEnum.AWAITING_DELIVERY,
                    OrderStatusEnum.PART_DELIVERED
                  ] as string[]
                ).includes(orderStatus)
              }
              onClick={onReceiveOutstanding}
            >
              {t('purchase_order.order_items.action.receive_outstanding')}
            </MaActionMenuItem>
          )}
        </MaActionMenu>
      </MaPanel.Header>
      <MaPanel.Body>
        {lineItems && lineItems.length > 0 ? (
          compactView || selectedView === ViewType.Card ? (
            <OrderLineItems
              items={lineItems}
              max={10}
              showAllOption={true}
              selectable={canEditSpendCategories}
              selectedLineItemIds={selectedLineItemIds}
              canEdit={canEditSpendCategories}
              onEditLineItem={onEditLineItem}
              onItemSelectedChange={onItemSelectedChange}
            />
          ) : (
            <PurchaseOrderOrderItemsTable
              lineItems={lineItems}
              selectedLineItemIds={selectedLineItemIds}
              onItemSelectedChange={onItemSelectedChange}
              editable={canEditSpendCategories}
              onItemEdit={onEditLineItem}
            />
          )
        ) : (
          <p>{t('order.items.none')}</p>
        )}
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderOrderItems
