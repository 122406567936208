import React, { type ReactElement } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import useOrderRequestSummary from '../hooks/useOrderRequestSummary'
import { type OrderRequestLineItem } from '@amici/myamici-api-client'
import styles from '../assets/scss/OrderRequestSetCustomPoRefsModal.module.scss'
import { Col, Form, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import MaConfirm from '../../common/components/MaConfirm'
import { useToastNotification } from '../../common/components/ToastNotificationContextProvider'
import useOrderRequestHistory from '../hooks/useOrderRequestHistory'
import useOrderRequestSuppliers from '../hooks/useOrderRequestSuppliers'
import useOrderRequestTrackingEvent from '../hooks/useOrderRequestTrackingEvent'

interface OrderRequestEditCustomOrderRefsModalProps {
  show: boolean
  orderRequestId: string
  lineItems: OrderRequestLineItem[]
  onClose: () => void
}

function OrderRequestEditCustomOrderRefsModal ({
  show,
  orderRequestId,
  lineItems,
  onClose
}: Readonly<OrderRequestEditCustomOrderRefsModalProps>): ReactElement | null {
  const { t } = useTranslation()
  const { suppliers } = useOrderRequestSummary(lineItems)
  const { mutate: refreshHistory } = useOrderRequestHistory(orderRequestId)
  const { supplierDetails, handleUpdate } =
    useOrderRequestSuppliers(orderRequestId)
  const { showToastMessage } = useToastNotification()
  const { trackEvent } = useOrderRequestTrackingEvent()

  const { register, getValues, reset } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange'
  })

  const handleConfirm = async (): Promise<void> => {
    onClose()
    trackEvent('update_order_request_custom_order_refs')
    const formFields = getValues()
    const newSupplierDetails = supplierDetails.map(supplier => ({
      ...supplier,
      customOrderRef: formFields[supplier.supplierId]
    }))
    try {
      await handleUpdate(newSupplierDetails)
      void refreshHistory()
    } catch {
      showToastMessage(
        'danger',
        t('order_request.custom_order_ref.error', { count: suppliers.length })
      )
    }
  }

  const handleClose = (): void => {
    onClose()
    reset()
  }

  if (suppliers.length === 0) {
    return null
  }

  return (
    <MaConfirm
      className={styles.modal}
      size="lg"
      title={t('order_request.custom_order_ref.title')}
      confirmLabel={t('common.button.labels.save')}
      closeLabel={t('common.button.labels.cancel')}
      show={show}
      onConfirm={handleConfirm}
      onClose={handleClose}
    >
      <p>
        <Trans
          i18nKey={'order_request.custom_order_ref.instructions'}
          values={{
            count: suppliers.length,
            supplier_name: suppliers[0].name
          }}
        />
      </p>
      <Form>
        {suppliers.map(supplier => (
          <Row className={styles['order-request-modal-row']} key={supplier.id}>
            <Form.Group
              className="mb-3"
              as={Col}
              lg={4}
              controlId={`${supplier.id}`}
            >
              {suppliers.length > 1 && (
                <Form.Label column={true}>
                  <strong>{supplier.name}</strong>
                </Form.Label>
              )}
              <Form.Control
                {...register(`${supplier.id}`)}
                name={`${supplier.id}`}
                maxLength={20}
                defaultValue={
                  supplierDetails.find(s => s.supplierId === supplier.id)
                    ?.customOrderRef ?? ''
                }
              />
            </Form.Group>
          </Row>
        ))}
      </Form>
    </MaConfirm>
  )
}

export default OrderRequestEditCustomOrderRefsModal
