import { type HistoryValue, type HistoryEvent } from '@amici/myamici-api-client'
import type { ReactNode } from 'react'
import styles from '../assets/scss/OrderRequest.module.scss'
import { Trans, useTranslation } from 'react-i18next'
import { formatDelimitedAddress } from '../../common/utils/format-address'
import MaDescriptionList from '../../common/components/MaDescriptionList'
import { OrderRequestHistoryEventType } from '../types/order-request-history-event-type'
import {
  DateFormat,
  formatDate,
  formatTime,
  TimeFormat
} from '../../common/utils/format-date-time'

function editFieldEntryType (
  oldValuePresent: boolean,
  newValuePresent: boolean
): 'removed' | 'added' | 'updated' {
  if (oldValuePresent && !newValuePresent) {
    return 'removed'
  } else if (!oldValuePresent && newValuePresent) {
    return 'added'
  }
  return 'updated'
}

function OrderRequestHistoryEvent ({
  i18nKey,
  values
}: Readonly<{
  i18nKey: string
  values: Record<string, string | undefined>
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <Trans i18nKey={i18nKey} values={values} />
    </div>
  )
}

function OrderRequestHistoryCreateEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  return (
    <OrderRequestHistoryEvent
      i18nKey={'order_request.history.create_order_request'}
      values={{
        account_name: entry.account_name,
        date: formatDate(entry.date, DateFormat.DATE_LONG),
        time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
      }}
    />
  )
}

function OrderRequestHistoryEditSummaryEntry ({
  historyKey,
  historyValue,
  entry
}: Readonly<{
  historyKey: string
  historyValue: HistoryValue
  entry: HistoryEvent
}>): ReactNode {
  const { t } = useTranslation()

  const editType = editFieldEntryType(
    !!historyValue.old_value,
    !!historyValue.new_value
  )

  if (entry.changes?.confidential && historyKey === 'confidential') {
    historyValue = {
      old_value: t(
        `order_request.history.confidential.${entry.changes?.confidential?.old_value?.toLowerCase()}`
      ),
      new_value: t(
        `order_request.history.confidential.${entry.changes?.confidential?.new_value?.toLowerCase()}`
      )
    }
  }

  if (entry.changes?.date_required && historyKey === 'date_required') {
    const oldValue = entry.changes?.date_required.old_value
      ? formatDate(entry.changes?.date_required.old_value, DateFormat.DATE_LONG)
      : entry.changes?.date_required.old_value

    const newValue = entry.changes?.date_required.new_value
      ? formatDate(entry.changes?.date_required.new_value, DateFormat.DATE_LONG)
      : entry.changes?.date_required.new_value

    historyValue = {
      new_value: newValue,
      old_value: oldValue
    }
  }

  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request.${historyKey}`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request.${editType}`}
          values={historyValue}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryAddLineItemEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={'order_request.history.add_order_request_line_item'}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={'order_request.history.add_order_request_line_item.added'}
          values={{
            supplier_part_number: entry.custom_data?.supplier_part_number,
            product_name: entry.changes?.product?.new_value
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryRemoveLineItemEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={'order_request.history.delete_order_request_line_item'}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={
            'order_request.history.delete_order_request_line_item.removed'
          }
          values={{
            supplier_part_number: entry.custom_data?.supplier_part_number,
            product_name: entry.changes?.product?.old_value
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryStatusChangeEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.${entry.event}`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
      </div>
      {entry.reason && <div>{entry.reason}</div>}
    </div>
  )
}

function OrderRequestHistoryDeliveryAddress ({
  historyKey,
  entry
}: Readonly<{
  historyKey: string
  entry: HistoryEvent
}>): ReactNode {
  const { t } = useTranslation()
  const oldAddressItems = entry.changes?.delivery_address?.old_value
    ? [
        {
          key: '1',
          term: t('common.label.from'),
          details: formatDelimitedAddress(
            entry.changes?.delivery_address?.old_value
          )
        }
      ]
    : []
  const newAddressItems = entry.changes?.delivery_address.new_value
    ? [
        {
          key: '2',
          term: t('common.label.to'),
          details: formatDelimitedAddress(
            entry.changes?.delivery_address?.new_value
          )
        }
      ]
    : []

  return (
    <>
      <Trans
        i18nKey={`order_request.history.edit_order_request_delivery_details.${historyKey}`}
        values={{
          account_name: entry.account_name,
          date: formatDate(entry.date, DateFormat.DATE_LONG),
          time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
        }}
      />
      <div className={styles['delivery-info']}>
        <div className={styles['address-history']}>
          <MaDescriptionList items={oldAddressItems} />
          <MaDescriptionList items={newAddressItems} />
        </div>
      </div>
    </>
  )
}

function OrderRequestHistoryDeliveryDetailsEntry ({
  historyKey,
  historyValue,
  entry
}: Readonly<{
  historyKey: string
  historyValue: HistoryValue
  entry: HistoryEvent
}>): ReactNode {
  let HistoryEvent: ReactNode

  if (entry.changes?.delivery_address) {
    return (
      <OrderRequestHistoryDeliveryAddress
        historyKey={historyKey}
        entry={entry}
      />
    )
  } else {
    const editType = editFieldEntryType(
      !!historyValue.old_value,
      !!historyValue.new_value
    )

    HistoryEvent = (
      <>
        <Trans
          i18nKey={`order_request.history.edit_order_request_delivery_details.${historyKey}`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
        <div>
          <Trans
            i18nKey={`order_request.history.edit_order_request.${editType}`}
            values={{
              ...historyValue,
              ...entry.custom_data
            }}
          />
        </div>
      </>
    )
  }

  return <div className={styles['history-event']}>{HistoryEvent}</div>
}

function OrderRequestHistoryEditLineItemEntry ({
  historyKey,
  historyValue,
  entry
}: Readonly<{
  historyKey: string
  historyValue: HistoryValue
  entry: HistoryEvent
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request_line_item.${historyKey}`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE),
            ...historyValue,
            ...entry.custom_data
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryEditLineItemSpendCategoryEntry ({
  historyKey,
  historyValue,
  entry
}: Readonly<{
  historyKey: string
  historyValue: HistoryValue
  entry: HistoryEvent
}>): ReactNode {
  const editType = editFieldEntryType(
    !!historyValue.old_value,
    !!historyValue.new_value
  )
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request_line_item_spend_category.${historyKey}.${editType}`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE),
            ...historyValue,
            ...entry.custom_data
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryCreateOrdersEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.create_purchase_orders.heading`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE)
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={`order_request.history.create_purchase_orders.order_references`}
          values={{
            order_references: entry?.custom_data?.order_references
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryEditCustomOrderRefEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  const oldValue = entry.changes?.custom_order_ref?.old_value
  const newValue = entry.changes?.custom_order_ref?.new_value
  const supplierName = entry.custom_data?.supplier_name
  const context = oldValue ? (newValue ? 'change' : 'removal') : 'addition'
  return (
    <div className={styles['history-event']}>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request_custom_order_ref.heading`}
          values={{
            account_name: entry.account_name,
            date: formatDate(entry.date, DateFormat.DATE_LONG),
            time: formatTime(entry.date, TimeFormat.TIME_SIMPLE),
            supplier_name: supplierName
          }}
        />
      </div>
      <div>
        <Trans
          i18nKey={`order_request.history.edit_order_request_custom_order_ref`}
          values={{
            context,
            oldValue,
            newValue
          }}
        />
      </div>
    </div>
  )
}

function OrderRequestHistoryEventEntry ({
  entry
}: Readonly<{
  entry: HistoryEvent
}>): ReactNode {
  let eventEntry = null
  switch (entry.event) {
    case OrderRequestHistoryEventType.CREATE_ORDER_REQUEST:
      eventEntry = <OrderRequestHistoryCreateEntry entry={entry} />
      break
    case OrderRequestHistoryEventType.CREATE_PURCHASE_ORDERS:
      eventEntry = <OrderRequestHistoryCreateOrdersEntry entry={entry} />
      break
    case OrderRequestHistoryEventType.EDIT_ORDER_REQUEST:
      eventEntry = (
        <>
          {Object.entries(entry.changes ?? {}).map(
            ([historyKey, historyValue], index) =>
              entry.changes && (
                <OrderRequestHistoryEditSummaryEntry
                  key={index}
                  historyKey={historyKey}
                  historyValue={historyValue}
                  entry={entry}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEventType.ADD_ORDER_REQUEST_LINE_ITEM:
      eventEntry = <OrderRequestHistoryAddLineItemEntry entry={entry} />
      break
    case OrderRequestHistoryEventType.EDIT_ORDER_REQUEST_LINE_ITEM:
      eventEntry = (
        <>
          {Object.entries(entry.changes ?? {}).map(
            ([historyKey, historyValue], index) =>
              entry.changes && (
                <OrderRequestHistoryEditLineItemEntry
                  key={index}
                  historyKey={historyKey}
                  historyValue={historyValue}
                  entry={entry}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEventType.EDIT_ORDER_REQUEST_LINE_ITEM_SPEND_CATEGORY:
      eventEntry = (
        <>
          {Object.entries(entry.changes ?? {}).map(
            ([historyKey, historyValue], index) =>
              entry.changes && (
                <OrderRequestHistoryEditLineItemSpendCategoryEntry
                  key={index}
                  historyKey={historyKey}
                  historyValue={historyValue}
                  entry={entry}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEventType.DELETE_ORDER_REQUEST_LINE_ITEM:
      eventEntry = <OrderRequestHistoryRemoveLineItemEntry entry={entry} />
      break
    case OrderRequestHistoryEventType.EDIT_ORDER_REQUEST_DELIVERY_DETAILS:
      eventEntry = (
        <>
          {Object.entries(entry.changes ?? {}).map(
            ([historyKey, historyValue], index) =>
              entry.changes && (
                <OrderRequestHistoryDeliveryDetailsEntry
                  key={index}
                  historyKey={historyKey}
                  historyValue={historyValue}
                  entry={entry}
                />
              )
          )}
        </>
      )
      break
    case OrderRequestHistoryEventType.CANCEL_ORDER_REQUEST:
    case OrderRequestHistoryEventType.SUBMIT_ORDER_REQUEST:
    case OrderRequestHistoryEventType.APPROVE_ORDER_REQUEST:
    case OrderRequestHistoryEventType.REJECT_ORDER_REQUEST:
    case OrderRequestHistoryEventType.WITHDRAW_ORDER_REQUEST:
      eventEntry = <OrderRequestHistoryStatusChangeEntry entry={entry} />
      break
    case OrderRequestHistoryEventType.EDIT_ORDER_REQUEST_CUSTOM_ORDER_REF:
      eventEntry = <OrderRequestHistoryEditCustomOrderRefEntry entry={entry} />
      break
  }

  return eventEntry
}

export default OrderRequestHistoryEventEntry
