import { type ReactElement, type HTMLAttributes } from 'react'
import {
  type OrderReference,
  type PurchaseOrderResource
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import format from 'date-fns/format'
import { formatAddress } from '../../common/utils/format-address'
import MaDescriptionList, {
  type DescriptionListItem
} from '../../common/components/MaDescriptionList'
import MaPanel from '../../common/components/MaPanel'
import useDateLocale from '../../common/hooks/useDateLocale'
import useOrderItems from '../hooks/useOrderItems'
import styles from '../assets/scss/PurchaseOrderPanel.module.scss'
import { Link } from 'react-router-dom'

function ConsolidatedPurchaseOrderList ({
  orderId,
  orderReferences
}: Readonly<{
  orderId: string
  orderReferences: OrderReference[]
}>): ReactElement {
  return (
    <div className="d-flex">
      {orderReferences.map((orderRef, index) => (
        <div key={orderRef.id}>
          {orderRef.id === orderId ? (
            <span>{orderRef.reference}</span>
          ) : (
            <Link to={`/purchasing/orders/${orderRef.id}`}>
              {orderRef.reference}
            </Link>
          )}

          {index < orderReferences.length - 1 && ', '}
        </div>
      ))}
    </div>
  )
}

function PurchaseOrderDetails ({
  order,
  ...props
}: HTMLAttributes<HTMLElement> & {
  readonly order: PurchaseOrderResource
}): ReactElement {
  const { t } = useTranslation()
  const dateLocale = useDateLocale()

  const { estimatedDeliveryDate } = useOrderItems(order?.id)

  const descriptionItems: DescriptionListItem[] = [
    {
      term: t('purchase_order.details.ordered_by'),
      details: order.ordered_by?.name,
      highlight: true
    },
    {
      term: t('purchase_order.details.order_date'),
      details: format(new Date(order.order_date ?? 0), 'do MMMM yyyy', {
        locale: dateLocale
      }),
      highlight: true
    },
    {
      term: t('purchase_order.details.address'),
      details: formatAddress(
        order.delivery_address,
        order.ordered_by?.client?.name
      )
    },
    {
      term: t('purchase_order.details.method'),
      details: t(`purchase_order.details.method_name.${order.method}`),
      highlight: true
    },
    ...(order.consolidations && order.consolidations.length > 0
      ? [
          {
            term: t('purchase_order.details.consolidates'),
            details: (
              <ConsolidatedPurchaseOrderList
                orderId={order.id}
                orderReferences={order.consolidations}
              />
            )
          }
        ]
      : []),
    {
      term: t('purchase_order.details.supplied_by'),
      details: order.supplier?.name,
      highlight: true
    },
    {
      term: t('purchase_order.details.account_no'),
      details: order.ship_to_account_ref,
      highlight: true
    },
    {
      term: t('purchase_order.details.order_type'),
      details: t(`purchase_order.details.order_type_name.${order.order_type}`),
      highlight: true
    },
    {
      term: t('purchase_order.details.po_note'),
      details: order.po_notes
    }
  ]

  return (
    <MaPanel className={props.className} {...props}>
      <MaPanel.Header className={styles.header}>
        <h4 className={styles.title}>
          <span>{t('purchase_order.details.title')}</span>
          <span>
            {t(`order.status.${order.status ?? ''}`, {
              ...(estimatedDeliveryDate && {
                date: estimatedDeliveryDate
              })
            })}
          </span>
        </h4>
      </MaPanel.Header>

      <MaPanel.Body className={styles.body}>
        <MaDescriptionList
          className={styles.list}
          items={descriptionItems.slice(
            0,
            Math.ceil(descriptionItems.length / 2)
          )}
        />
        <MaDescriptionList
          className={styles.list}
          items={descriptionItems.slice(Math.ceil(descriptionItems.length / 2))}
        />
      </MaPanel.Body>
    </MaPanel>
  )
}

export default PurchaseOrderDetails
