import { type HTMLAttributes, type ReactElement, useId } from 'react'
import {
  type SpendCategory,
  type SpendCategoryField,
  SpendCategoryFieldStatusEnum
} from '@amici/myamici-api-client'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import useSpendCategories from '../hooks/useSpendCategories'
import SpendCategorySelect from './SpendCategorySelect'
import useSpendCategoriesTree from '../hooks/useSpendCategoriesTree'
import styles from '../assets/scss/SpendCategoryField.module.scss'

interface SpendCategoryFieldSelectProps extends HTMLAttributes<HTMLDivElement> {
  assignedFieldIds?: string[]
  selectedSpendCategories: SpendCategory[]
  excludeInactive?: boolean
  disabled?: boolean
  compactView?: boolean
  allowEmpty: boolean
  placeholder?: string
  emptyNodeName?: string
  onValueChange: (newSpendCategory: SpendCategory) => void
}

function SpendCategoryFieldSelect ({
  assignedFieldIds,
  selectedSpendCategories,
  excludeInactive,
  disabled,
  compactView,
  allowEmpty,
  emptyNodeName,
  placeholder,
  onValueChange
}: Readonly<SpendCategoryFieldSelectProps>): ReactElement {
  const id = useId()
  const { t } = useTranslation()
  const { data, isLoading } = useSpendCategories()
  const { getEmptySpendCategory } = useSpendCategoriesTree([])

  const spendCategoryFields = (data ?? []).filter(spendCategoryField =>
    excludeInactive
      ? spendCategoryField.status === SpendCategoryFieldStatusEnum.ACTIVE
      : true
  )

  const getSpendCategory = (
    spendCategoryField: SpendCategoryField
  ): SpendCategory | undefined =>
    selectedSpendCategories.find(
      selectedSpendCategory =>
        selectedSpendCategory.field_id === spendCategoryField.id
    )

  const onUpdate = (spendCategoryId: number, index: number): void => {
    const spendCategoryField = spendCategoryFields[index]

    const newSpendCategory =
      spendCategoryId === -1
        ? getEmptySpendCategory(spendCategoryField.id ?? '0')
        : spendCategoryField.spend_categories.find(
            spendCategory => spendCategory.id === spendCategoryId
          )

    if (!newSpendCategory) {
      return
    }
    onValueChange(newSpendCategory)
  }

  const getSelectPlaceholder = (
    spendCategoryFieldId: string,
    spendCategoryName?: string
  ): string => {
    if (!allowEmpty && !assignedFieldIds?.includes(spendCategoryFieldId)) {
      return t('common.label.please_select')
    }

    return spendCategoryName ?? placeholder ?? t('spend_categories.label.none')
  }

  return (
    <div
      className={classNames(styles['spend-category-fields'], {
        [styles.compact]: compactView
      })}
      data-testid="spend-category-fields"
    >
      {spendCategoryFields.map((spendCategoryField, index) => {
        const spendCategory = getSpendCategory(spendCategoryField)
        return (
          spendCategoryField.spend_categories.length > 0 && (
            <div
              className={classNames(styles['spend-category'], {
                [styles.compact]: compactView
              })}
              key={spendCategoryField.id}
            >
              <label
                className={styles.name}
                htmlFor={`${spendCategoryField.name}-${id}`}
              >
                {spendCategoryField.name}
              </label>
              <SpendCategorySelect
                id={`${spendCategoryField.name}-${id}`}
                disabled={disabled || isLoading}
                value={spendCategory?.id.toString()}
                placeholder={getSelectPlaceholder(
                  spendCategoryField.id,
                  spendCategory?.name
                )}
                spendCategories={spendCategoryField.spend_categories}
                allowEmpty={allowEmpty}
                emptyNodeName={emptyNodeName}
                onValueChange={value => {
                  onUpdate(parseInt(value), index)
                }}
                invalid={spendCategory?.status === 'inactive'}
              />
            </div>
          )
        )
      })}
    </div>
  )
}

export default SpendCategoryFieldSelect
