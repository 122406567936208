import { Button, Spinner } from 'react-bootstrap'
import { type ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import { useToastNotification } from './ToastNotificationContextProvider'
import styles from '../assets/scss/ExportCsvButton.module.scss'
import useAutoDownload from '../hooks/useAutoDownload'

export interface ExportCsvButtonProps {
  fetchData: () => Promise<string[][]>
  onExportStart?: () => void
}

function ExportCsvButton ({
  fetchData,
  onExportStart
}: Readonly<ExportCsvButtonProps>): ReactElement {
  const { showToastMessage } = useToastNotification()
  const download = useAutoDownload()
  const { t } = useTranslation()
  const [exporting, setExporting] = useState(false)

  const onClick = async (): Promise<void> => {
    setExporting(true)
    onExportStart?.()
    try {
      const data = await fetchData()
      const objectURL = URL.createObjectURL(
        new Blob(
          [
            '\ufeff',
            data
              .map(row =>
                row.map(value => `"${value.replace(/"/g, '""')}"`).join(',')
              )
              .join('\r\n')
          ],
          {
            type: 'text/csv'
          }
        )
      )
      download(objectURL, 'OrderList.csv')
      URL.revokeObjectURL(objectURL)
    } catch {
      showToastMessage('danger', t('order.export.failure'))
    }
    setExporting(false)
  }

  return (
    <Button
      variant="outline-primary"
      className={classNames(['rounded', styles.button])}
      disabled={exporting}
      onClick={() => {
        void onClick()
      }}
    >
      {t('common.button.labels.export')}
      {exporting && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
    </Button>
  )
}

export default ExportCsvButton
