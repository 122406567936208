import type { SpendCategory } from '@amici/myamici-api-client'
import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'
import GroupEditSpendCategoriesModal from '../../common/components/GroupEditSpendCategoriesModal'

interface PurchaseOrderGroupEditSpendCategoriesModalProps {
  assignedFieldIds?: string[]
  show: boolean
  onSave: (spendCategories: SpendCategory[]) => Promise<void>
  onCancel: () => void
  disabled: boolean
}

function PurchaseOrderGroupEditSpendCategoriesModal (
  props: Readonly<PurchaseOrderGroupEditSpendCategoriesModalProps>
): ReactElement {
  const { t } = useTranslation()

  return (
    <GroupEditSpendCategoriesModal
      allowEmpty={false}
      title={t('purchase_order.group_edit_spend_categories.title')}
      {...props}
    >
      <p className="small">
        {t('purchase_order.group_edit_spend_categories.message')}
      </p>
    </GroupEditSpendCategoriesModal>
  )
}

export default PurchaseOrderGroupEditSpendCategoriesModal
